import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ratingRequest } from './action'
import { List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { 
    faTimesCircle,
    faCheckCircle,
    faExclamationTriangle,
    faExclamationCircle 
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleMaterialTable from '../../shared/SimpleMaterialTable'
import { useKeycloak } from '@moxoff/axiom-auth'
import { validate } from './validate'
import { Stack } from '@mui/material'


const Home = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    
    const { keycloak, authenticated } = useKeycloak()

    const rating = useSelector(state => state.home.rating) || []

    //const [input, setInput] = useState('')
    

   const getToolTipValue = (area) =>{

    switch(area){
      case "area1": return ("<=5 MWh - <=10 KSmc")
      case "area2": return (">5 e <= 30 MWh - >10 e <=30 KSmc")
      case "area3": return (">30 e <= 200 MWh - >30 e <= 100 KSmc")
      case "area4": return (">200 MWh - >100KSmc") 
      default: return ("area is not in range")

    }

   }

    const tooltipCondition = (legend) => {
      switch (legend) {
        case "ele_a": return 'da 200Mwh a 1000Mwh SDD';
        case "ele_b": return 'da 1000Mwh a 3000Mwh SDD'; 
        case "ele_c": return '>3000MWh SDD';

        case "gas_a": return 'da 100Ksmc a 370Ksmc SDD';
        case "gas_b": return 'da 370Ksmc a 1110Ksmc SDD'; 
        case "gas_c": return 'da >1110 KSmc SDD';
        default: return "Richiede controllo da backoffice"


      }
    }
  
    const crediti = rating?.crediti
    const crediti_spesi = rating?.crediti_spesi
      
  
    const data = rating?.table?.reduce((acc, current) => {
        const obj = {}
        let tooltipLegend;
        

        
        const record = acc.find(o => o.area === current.area)
        const outComeResult = (
          current.area === 'Area4' 
          && (current.category === 'A' || current.category === 'B' || current.category === 'C')
          && current.outcome !== 'void'
          ) ? 'Yellow' : current.outcome
        tooltipLegend = current.outcome
        if(outComeResult === 'Yellow'){
         const legend = `${current.commodity.toLowerCase().concat('_').concat(current.category.toLowerCase())}`
              tooltipLegend = tooltipCondition(legend)  
        }
        if(record){
            record.category = current.category
            record[`${current.commodity.toLowerCase()}`] = outComeResult
            record[`${current.commodity.toLowerCase().concat('_tooltip')}`] = tooltipLegend
            record["areaToolTip"] = getToolTipValue(current.area.toLocaleLowerCase())
        } else {
            obj.category= current.category
            obj[`${current.commodity.toLowerCase()}`]= outComeResult
            obj[`${current.commodity.toLowerCase().concat('_tooltip')}`] = tooltipLegend
            obj["areaToolTip"] = getToolTipValue(current.area.toLocaleLowerCase())

            obj.area = current.area
            acc.push(obj)
        }
            
        return acc
    }, []).sort((a, b) => a.area > b.area ? 1 : -1)

  const onSubmit = async (values) => {
    await keycloak()?.updateToken(10) 
    const userProfile = (await authenticated())
    ? await keycloak().loadUserProfile()
    : {
        attributes: {}
      }
      const userInfo = (await authenticated())
      ? await keycloak().loadUserInfo()
      : {
          preferred_username: 'moxoff',
          name: 'Moxoff',
          email: 'info@moxoff.com'
      }
      const { code } = values
      //setInput(code)
      const type = !isNaN(code) ? 'piva' : 'cf'
      userInfo["attributes"] = userProfile["attributes"]
      dispatch(ratingRequest(code, type, userInfo, keycloak ? keycloak() : undefined))
    };

    const getIcon = (value, tooltip='', category = '') => {
        const idx = value ? value.indexOf("[") : -1
        let icon = value
        let subscript = ""
        if(idx !== -1){
            icon = value.substring(0, idx)
            subscript = value.substring(icon.length+1,value.length-1)
        }
        switch(icon){
            case 'Rosso':
                return (
                    <div style={{ textAlign: "left"}}>
                      <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                        <div> 
                          <FontAwesomeIcon icon={faTimesCircle} size="3x" color="red" />
                        </div>
                      </Tooltip> 
                      <h4>{subscript}</h4>
                    </div>
                )
            case 'RossoRosso':
              return (
                  <div style={{ textAlign: "left"}}>
                    <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                      <div> 
                       <FontAwesomeIcon icon={faTimesCircle} size="3x" color="red" />
                      </div>
                    </Tooltip> 
                    <h4>{subscript}</h4>
                  </div>
              )
            case 'RossoDual':
              return (
                  <div style={{ textAlign: "left"}}>
                    <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                      <div> 
                        <FontAwesomeIcon icon={faTimesCircle} size="3x" color="red" />
                      </div>
                    </Tooltip> 
                    <h4>{subscript}</h4>
                  </div>
              )
            case 'Giallo':  
                return (
                    <div style={{ textAlign: "left"}}>
                      <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                        <div> 
                          <FontAwesomeIcon icon={faCheckCircle} size="3x" color="yellow" />
                        </div>
                      </Tooltip> 
                      <h4>{subscript}</h4>
                    </div>)
            case 'Blu':  
                return (
                    <div style={{ textAlign: "left"}}>
                        <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                          <div> 
                            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" color="blue" />
                          </div>
                        </Tooltip>   
                        <h4  style={{ textAlign: "left"}}>{subscript}</h4>
                    </div>)
            case 'Verde':  
                return (
                    <div style={{ textAlign: "left"}}>
                      <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                          <div> 
                            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" />
                          </div>
                      </Tooltip>   
                      <h4>{subscript}</h4>
                    </div>)
            case 'Grigio':  
                return (
                    <div style={{ textAlign: "left"}}>
                      <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                        <div> 
                          <FontAwesomeIcon icon={faExclamationCircle} size="3x" color="grey" />
                        </div>
                      </Tooltip>  
                      <h4>{subscript}</h4>
                    </div>)
            case 'Yellow':  
            return (
                <div style={{ textAlign: "left"}}>
                  <Tooltip title={tooltip} place='left' enterTouchDelay={0}>
                    <div>  
                      <FontAwesomeIcon  icon={faExclamationCircle} size="3x" color="yellow" />
                      <Typography  style={{marginLeft:'15px',marginTop: '-40px', zIndex: '100'}} variant='h2'>{category}</Typography>
                    </div>
                  </Tooltip>  
                  <h4>{subscript}</h4>
                </div>)
            case 'area':  
            return (
              <div style={{ textAlign: "left" }}>
                <Tooltip title={tooltip.areaToolTip} place='left' enterTouchDelay={0}>
                  <div>  {tooltip.area}
                  </div>
                </Tooltip>
                <h4>{subscript}</h4>
              </div>)
            case 'void':  
                return <div></div>
            default:
                return <div style={{ textAlign: "left"}}><h4>{value}</h4></div>
        }
    }

    const columns = [{
        title: "",
        field: "area",
        type: "string",
        render: rec => {
          return  getIcon("area", rec)        
       }
       
    }, {
        title: "ELE",
        field: "ele",
        align: "left",
        headerStyle: {fontSize: '16px'}, 
        render: rec => {
          return  getIcon(rec.ele, rec.ele_tooltip, rec.category)        
       },
    },
    {
        title: "GAS",
        field: "gas",
        align: "left",
        headerStyle: {fontSize: '16px'}, 
        render: rec => {
          return getIcon(rec.gas, rec.gas_tooltip, rec.category)           
       },
    }, {
        title: "DUAL",
        field: "dual",
        align: "left",
        headerStyle: {fontSize: '16px'}, 
        render: rec => {
          return getIcon(rec.dual, rec.dual_tooltip)            
       },
    }]

    const eventi_negativi = rating?.table?.map(el => el.flag_eventi_negativi ? 1 : 0).reduce((a, b) => (a + b), 0) || 0

    return (
      <>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="code"
                render={({ input, meta }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      align: "center",
                      justifyContent: "center",
                      paddingLeft: "5vw",
                    }}
                  >
                    <TextField label="P.IVA" style={{ flex: 0.3 }} {...input} />
                    {meta.touched && meta.error && <span style={{ marginTop: 5, color: 'red'}}>{meta.error}</span>}
                  </div>
                )}
              />
            </form>
          )}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            align: "center",
            justifyContent: "center",
            paddingLeft: "3vw",
          }}
        >
        <Card className={classes.root}>
          <CardContent>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="column">
                {crediti && crediti_spesi && (
                  <Typography variant="h4" style={{ fontWeight: "bold" }}>
                    Hai usato {crediti_spesi} crediti su {crediti} crediti
                  </Typography>
                )}
                {rating && rating.table && rating.table.length > 0 && (
                  <>
                    <Typography variant="h5" style={{ marginTop: "10pt" }}>
                      {rating.table[0]?.denominazione}
                    </Typography>
                    <Typography style={{ marginBottom: "10pt" }}>
                      {`P.IVA:  ${rating.table[0]?.piva} / CF: ${rating.table[0]?.cf}`}
                    </Typography>
                    {eventi_negativi > 0 &&
                    (<Typography style={{ marginBottom: "10pt", fontWeight: "bold"}}>
                      Presenza di Eventi Negativi
                    </Typography>)
                    }
                  </>
                )}
                {rating.flag_messages?.map((message) => (
                  <Typography
                    key={message.id}
                    variant="h6"
                    gutterBottom
                    style={{ color: message.color, fontWeight: "bold" }}
                  >
                    {message.message}
                  </Typography>
                ))}
              </Stack>
              <List paddingBottom="4px">
                <ListItem style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                  <div
                    style={{
                      marginTop: "4px",
                      marginBottom: "4px",
                      minWidth: "65px",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    Verde:
                  </div>
                  <ListItemText primary="OK con qualsiasi MDP" />
                </ListItem>
                <ListItem style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                  <div
                    style={{
                      marginTop: "4px",
                      marginBottom: "4px",
                      minWidth: "65px",
                      color: "#f1c232",
                      fontWeight: "bold",
                    }}
                  >
                    Giallo:
                  </div>
                  <ListItemText primary="OK con SDD" />
                </ListItem>
                <ListItem style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                  <div
                    style={{
                      color: "blue",
                      marginTop: "4px",
                      marginBottom: "4px",
                      minWidth: "65px",
                      fontWeight: "bold",
                    }}
                  >
                    Blu:
                  </div>
                  <ListItemText primary="OK con SDD e listino indicato" />
                </ListItem>
                <ListItem style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                  <div
                    style={{
                      color: "red",
                      marginTop: "4px",
                      marginBottom: "4px",
                      minWidth: "65px",
                      fontWeight: "bold",
                    }}
                  >
                    Rosso:
                  </div>
                  <ListItemText primary="KO" />
                </ListItem>
                <ListItem style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                  <div
                    style={{
                      marginTop: "4px",
                      marginBottom: "4px",
                      minWidth: "65px",
                      fontWeight: "bold",
                    }}
                  >
                    NOREA:
                  </div>
                  <ListItemText primary="OK con SDD (max area 3)" />
                </ListItem>
              </List>
            </Stack>
            {data && data.length > 0 && (
              <SimpleMaterialTable
                title={""}
                rows={data}
                columns={columns}
                options={{
                  sorting: false,
                  exportButton: false,
                  filtering: false,
                  search: false,
                  paging: false,
                }}
              />
            )}
            {(!data || !data.length) && <h4>Non censito</h4>}
          </CardContent>
        </Card>
        </div>
      </>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
      
      marginLeft: '2vw',
      marginTop: '3vw'
      
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
  }));


export default Home
